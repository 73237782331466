<template>
    
    <v-card-title >
       
        <v-row dense>
            <v-col cols="12"> {{modulo}}: {{(cardInfo.nome || '' ||$route.params.username)}} - id: {{(cardInfo.id ||$route.params.id)}}</v-col>
            <v-col cols="12">
                <v-divider></v-divider>
            </v-col>
        </v-row>
        
    </v-card-title>

</template>
<script>


export default {
    name:"my-card-title",
    props:{
        cardInfo: {
            type: Object,
            default() {
                return {id:'', nome: null,}
            }
        },
        modulo:{
            type: String
        }
    },
    data(){
        return{
            dialog:false
        }
    }
}
</script>